import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const createCompetitionSelector = () => createSelector(
  ({ competition }) => competition,
  competition => competition,
);

export const useCompetition = () => {
  const selector = useMemo(createCompetitionSelector, []);
  const { competition: { competition, sport, area } = {}, status } = useSelector(selector);
  return { status, competition: { ...competition, sport, area } };
};
