import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../../../../components/Layout.js';
import Loading from '../../../../../components/Loading.js';
import Title from '../../../../../components/typography/Title.js';
import TallContainer from '../../../../../components/TallContainer.js';
import { loadCompetition } from '../../../../../stores/Competition/CompetitionActions.js';
import { useCompetition } from '../../../../../stores/Competition/CompetitionSelectors.js';
import Status, { isLoadingStatus } from '../../../../../stores/Status.js';
import { useExistingBanner } from '../../../../../stores/ExistingBanners/ExistingBannersSelectors.js';
import ExistingBanner from '../../../../../components/existing-banners/ExistingBanner.js';
import BannerForm, { BannerTypes } from '../../../../../components/forms/BannerForm.js';
import { loadCompetitionBanner } from '../../../../../stores/ExistingBanners/ExistingBannersActions.js';

const CompetitionBannerDelegate = () => {
  const dispatch = useDispatch();
  const { competition } = useCompetition();
  const { status } = useExistingBanner();

  useEffect(() => {
    dispatch(loadCompetitionBanner({ competitionId: competition.id, sportId: competition.sport.id })).then();
  }, [competition.id, competition.sport.id, dispatch]);

  if (isLoadingStatus(status)) {
    return <Loading />;
  }

  if (status === Status.OK) {
    return <ExistingBanner type={BannerTypes.Competition} sport={competition.sport} competition={competition} />;
  }

  return (
    <div>
      <p className="banner__description">
        There isn't an active banner for {competition.name} at the moment. You can add one below...
      </p>
      <BannerForm
        type={BannerTypes.Competition}
        sport={competition.sport}
        competitionId={competition.id}
      />
    </div>
  );
};

const CompetitionLoader = ({ sportId, competitionId }) => {
  const dispatch = useDispatch();
  const { status } = useCompetition();

  useEffect(() => {
    if (sportId && competitionId) {
      dispatch(loadCompetition(sportId, competitionId)).then();
    }
  }, [sportId, competitionId, dispatch]);

  if (isLoadingStatus(status)) {
    return <Loading message="Loading competition..." />;
  }

  return <CompetitionBannerDelegate />;
};

const withCompetitionTitle = () => () => {
  const { competition: { name } = {} } = useCompetition();

  return name
    ? <Title black={name} blue="Banner" />
    : null;
};

export default function CompetitionBanner({ params: { sportId, competitionId } }) {
  if (!competitionId || !sportId) {
    return <Loading />;
  }

  return (
    <Layout TitleElement={withCompetitionTitle()}>
      <TallContainer>
        <CompetitionLoader competitionId={competitionId} sportId={sportId} />
      </TallContainer>
    </Layout>
  );
};
