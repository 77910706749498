import Status from '../Status.js';
import { SET_COMPETITION, SET_STATUS } from './CompetitionReducer.js';
import ApiService from '../../services/ApiService.js';
import { setErrors } from '../Errors/ErrorsActions.js';

const setStatus = status => ({
  type: SET_STATUS,
  status,
});

const setCompetition = competition => ({
  type: SET_COMPETITION,
  competition,
});

export const loadCompetition = (sportId, competitionId) => async dispatch => {
  dispatch(setStatus(Status.Loading));

  const { sport, area, competition } = await ApiService.getInstance().getCompetition(sportId, competitionId);

  if (!competition) {
    return dispatch(setErrors(['Failed to fetch competition']));
  }

  dispatch(setCompetition({ sport, area, competition }));
};
